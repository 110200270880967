<template>
  <div class="page-404 pt-5">
    <b-container class="container-404 py-5 d-flex flex-column">
      <b-col cols="10" sm="4" md="4" xl="3" class="py-5 mx-auto">
        <q-header level="1" size="xl" color="#fff">
          <b>Упс...</b>
        </q-header>
        <q-header level="2" size="s" color="#fff">
          Мы не можем найти страницу, которую вы ищете
        </q-header>
      </b-col>
      <b-col cols="12" sm="6" md="5" xl="3" class="mx-auto d-flex flex-column">
        <q-btn to="/" class="mx-auto" variant="secondary">Вернуться на главную</q-btn>
        <q-header level="6"  size="s" color="#fff" class="mt-2">
          Или зайдите <router-link to="/authorization" class="link-404">в личный кабинет</router-link>
        </q-header>
      </b-col>
    </b-container>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
html[md="true"][bluebg="true"] body {
  background-color: var(--theme-accent-light) !important;
}

.page-404 {
  min-height: 45vh;
}
</style>

<style scoped lang="scss">
.container-404 {
  background: url("~@/assets/images/site/404.svg") no-repeat top;
  background-size: contain;
}

.link-404 {
  color: #fff;
  text-decoration: underline;
  text-underline-offset: 4px;
}
</style>
